export const locale = {
  lang: "en",
  data: {
    MENU: {
      DASHBOARD: {
        COLLAPSIBLE: "Dashboards",
        BADGE: "2",
        ANALYTICS: "Analytics",
        ECOMMERCE: "eCommerce",
      },

      MAIN: {
        SECTION: "Main",
        Main: "Main",
        EVENTS: "EVENTS",
        OFFERS: "OFFERS",
        STUDENTS: "STUDENTS",
        SUBSCRIPTIONS: "SUBSCRIPTIONS",

        DEVICES: {
          COLLAPSIBLE: "Devices",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },

        INVOICE: {
          COLLAPSIBLE: "Invoice",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
        ECOMMERCE: {
          COLLAPSIBLE: "eCommerce",
          SHOP: "Shop",
          DETAIL: "Details",
          WISHLIST: "Wish List",
          CHECKOUT: "Checkout",
        },
        USER: {
          COLLAPSIBLE: "User",
          LIST: "List",
          VIEW: "View",
          EDIT: "Edit",
        },
      },
      SETTINGS: {
        SECTION: "settings",
        Settings: "Settings",

        ADMINS: {
          COLLAPSIBLE: "Admins",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
        AVATARS: {
          COLLAPSIBLE: "Avatars",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
        COUNTRIES: {
          COLLAPSIBLE: "Countries",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
        ONBOARDINGS: {
          COLLAPSIBLE: "Onboardings",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
        LEVELS: {
          COLLAPSIBLE: "Levels",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
        SCHOOLTYPES: {
          COLLAPSIBLE: "Schoole Types",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
        SUBJECTS: {
          COLLAPSIBLE: "Subjects",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
        QUIZZES: {
          COLLAPSIBLE: "Quizzes",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
        QUESTIONS: {
          COLLAPSIBLE: "Questions",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
        TOPICS: {
          COLLAPSIBLE: "Topics",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
        VIDEOS: {
          COLLAPSIBLE: "Videos",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
        CATEGORIES: {
          COLLAPSIBLE: "Categories",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
        PACKAGES: {
          COLLAPSIBLE: "Packages",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
        BUNDLES: {
          COLLAPSIBLE: "Bundles",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
        BRANCHES: {
          COLLAPSIBLE: "Branches",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
        TERMS_AND_CONDITIONS: {
          COLLAPSIBLE: "Terms and Conditions",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
        FAQS: {
          COLLAPSIBLE: "FAQs",
          LIST: "List",
          PREVIEW: "Preview",
          EDIT: "Edit",
          ADD: "Add",
        },
      },
    },
  },
};
