<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; 2024
    <a
      class="ml-25"
      href="https://1.envato.market/pixinvent_portfolio"
      target="_blank"
      >WAJAD-APP</a
    >
    <span class="d-none d-sm-inline-block">, All rights Reserved</span>
  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

<!-- Buynow Button-->
