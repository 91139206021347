import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { environment } from "environments/environment";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from "app/auth/service";

@Injectable({
  providedIn: "root",
})
export class ApisService {
  private domain = environment.productionDomain;
  private token = "";

  constructor(
    private http: HttpClient,
    private router: Router,
    private _authenticationService: AuthenticationService
  ) {
    this._authenticationService.tokenSubject.subscribe((res: any) => {
      this.token = res;
      console.log("token form apis", res);
      console.log("type of token ", typeof this.token);
      return res;
    });
  }

  private createHeaders(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "application/json",
      "Accept-Language": "en",
      Authorization: `Bearer ${this.token}`,
    });
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401 || error.error.message === "Unauthenticated.") {
      // Redirect to login page on unauthenticated error
      this.router.navigate(["/auth/login"]);
    }
    // You can add more error handling logic here
    return throwError(error);
  }

  Login(email: string, password: string): Observable<any> {
    const headers = this.createHeaders();
    return this.http
      .post(
        `${this.domain}/api/dashboard-api/login`,
        { email, password },
        { headers }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  private authenticatedGet(url: string): Observable<any> {
    const headers = this.createHeaders();
    return this.http
      .get(url, { headers })
      .pipe(catchError(this.handleError.bind(this)));
  }

  private authenticatedPut(url: string, data: any): Observable<any> {
    let headers = this.createHeaders();

    // Remove the 'Content-Type' header if sending FormData
    if (data instanceof FormData) {
      headers = headers.delete("Content-Type");
    }

    return this.http
      .put(url, data, { headers })
      .pipe(catchError(this.handleError.bind(this)));
  }

  private authenticatedPost(url: string, data: any): Observable<any> {
    let headers = this.createHeaders();

    // Remove the 'Content-Type' header if sending FormData
    if (data instanceof FormData) {
      headers = headers.delete("Content-Type");
    }

    return this.http
      .post(url, data, { headers })
      .pipe(catchError(this.handleError.bind(this)));
  }

  SchoolTypes(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/school-types`
    );
  }

  levels(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/levels`
    );
  }

  levelById(levelId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/levels/${levelId}`
    );
  }

  updateLevels(formData: any, levelId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/levels/${levelId}?_method=PUT`,
      formData
    );
  }

  levelsBranches(levelId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/level-branches`,
      { level_id: levelId }
    );
  }

  levelsSubjects(levelId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/level-subjects`,
      { level_id: levelId }
    );
  }

  /**
   * Subjects APIS.
   * -----------------------------------------------------------------------------
   */

  Subjects(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/subjects`
    );
  }

  subjectById(subjectId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/subjects/${subjectId}`
    );
  }

  addSubject(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/subjects`,
      formData
    );
  }

  updateSubject(formData: any, subjectId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/subjects/${subjectId}?_method=PUT`,
      formData
    );
  }

  removeSubject(subjectId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/subjects/${subjectId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  addSubjectToLevel(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/add-subject-to-level`,
      formData
    );
  }

  removeSubjectFromLevel(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/remove-level-subject`,
      formData
    );
  }

  /**
   * End of subjects APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Videos APIS.
   * -----------------------------------------------------------------------------
   */

  Videos(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/videos`
    );
  }

  videoById(videoId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/videos/${videoId}`
    );
  }

  addVideo(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/videos`,
      formData
    );
  }

  updateVideo(formData: any, videoId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/videos/${videoId}?_method=PUT`,
      formData
    );
  }

  removeVideo(videoId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/videos/${videoId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }
  topicVideos(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/videos-by-topic`,
      formData
    );
  }

  /**
   * End of Videos APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Packages APIS.
   * -----------------------------------------------------------------------------
   */

  Packages(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/packages`
    );
  }

  packageById(packageId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/packages/${packageId}`
    );
  }

  addPackage(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/packages`,
      formData
    );
  }

  updatePackage(formData: any, packageId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/packages/${packageId}?_method=PUT`,
      formData
    );
  }

  removePackage(packageId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/packages/${packageId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  packageSubjects(packageId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/packages/${packageId}/subjects`
    );
  }

  addSubjectToPackage(packageId: any, subjectId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/packages/${packageId}/subjects?subject_id=${subjectId}`,
      {}
    );
  }

  removeSubjectFromPackage(packageId: any, subjectId: any): Observable<any> {
    return this.http
      .delete(
        `${this.domain}/api/dashboard-auth-api/packages/${packageId}/subjects?subject_id=${subjectId}`,
        {
          headers: this.createHeaders(),
        }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * End of Packages APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Categories APIS.
   * -----------------------------------------------------------------------------
   */

  Categories(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/categories`
    );
  }

  categoryById(categoryId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/categories/${categoryId}`
    );
  }

  addCategory(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/categories`,
      formData
    );
  }

  updateCategory(formData: any, categoryId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/categories/${categoryId}?_method=PUT`,
      formData
    );
  }

  removeCategory(categoryId: any): Observable<any> {
    return this.http
      .delete(
        `${this.domain}/api/dashboard-auth-api/categories/${categoryId}`,
        {
          headers: this.createHeaders(),
        }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * End of Categories APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Bundles APIS.
   * -----------------------------------------------------------------------------
   */

  Bundles(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/bundles`
    );
  }

  bundleById(bundleId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/bundles/${bundleId}`
    );
  }

  addBundle(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/bundles`,
      formData
    );
  }

  updateBundle(formData: any, bundleId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/bundles/${bundleId}?_method=PUT`,
      formData
    );
  }

  removeBundle(bundleId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/bundles/${bundleId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  bundelsByPackage(packageId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/bundles-by-package`,
      { package_id: packageId }
    );
  }

  addBundleToPackage(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/add-bundle-to-package`,
      formData
    );
  }

  removeBundleFromPackage(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/remove-bundle-from-package`,
      formData
    );
  }

  /**
   * End of Bundles APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Info APIS.
   * -----------------------------------------------------------------------------
   */

  infoByPackage(packageId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/package-infos-for-package`,
      { package_id: packageId }
    );
  }

  addInfoToPackage(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/package-infos`,
      formData
    );
  }

  removeInfoFromPackage(infoId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/package-infos/${infoId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * End of Info APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Quizzes APIS.
   * -----------------------------------------------------------------------------
   */

  Quizzes(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/quizzes`
    );
  }

  quizById(quizId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/quizzes/${quizId}`
    );
  }

  addQuiz(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/quizzes`,
      formData
    );
  }

  updateQuiz(formData: any, quizId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/quizzes/${quizId}?_method=PUT`,
      formData
    );
  }

  removeQuiz(quizId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/quizzes/${quizId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  quizQuestions(quizId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/questions-for-quiz/${quizId}`
    );
  }

  /**
   * End of Quizzes APIS.
   * -----------------------------------------------------------------------------
   */


  /**
   * Quiz APIS.
   * -----------------------------------------------------------------------------
   */

  Questions(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/questions`
    );
  }

  questionById(questionId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/questions/${questionId}`
    );
  }

  updateQuestion(formData: any, questionId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/questions/${questionId}?_method=PUT`,
      formData
    );
  }

  removeQuestion(questionId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/questions/${questionId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }


  addQuestionToQuiz(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/questions`,
      formData
    );
  }

  /**
   * End of Quiz APIS.
   * -----------------------------------------------------------------------------
   */



  /**
   * Admins APIS.
   * -----------------------------------------------------------------------------
   */
  Admins(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/admins`
    );
  }

  addAdmin(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/admins`,
      formData
    );
  }

  updateAdmin(formData: any, adminId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/admins/${adminId}?_method=PUT`,
      formData
    );
  }

  removeAdmin(adminId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/admins/${adminId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  adminById(adminId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/admins/${adminId}`
    );
  }
  /**
   * End of Admins APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * School Types APIS.
   * -----------------------------------------------------------------------------
   */

  addSchoolTypes(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/school-types`,
      formData
    );
  }

  schoolTypeById(schoolTypeId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/school-types/${schoolTypeId}`
    );
  }

  updateSchoolTypes(formData: any, schoolTypeId: any): Observable<any> {
    console.log(schoolTypeId, formData);
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/school-types/${schoolTypeId}?_method=PUT`,
      formData
    );
  }

  addLevels(data: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/levels`,
      data
    );
  }

  // addSubjects(data: FormData): Observable<any> {
  //   return this.authenticatedPost(
  //     `${this.domain}/api/dashboard-auth-api/subjects`,
  //     data
  //   );
  // }

  removeSchoolType(typeId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/school-types/${typeId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  removeLevel(levelId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/levels/${levelId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * Avatars APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Retrieves a list of avatars from the dashboard-auth-api.
   *
   * @return {Observable<any>} An observable containing the list of avatars.
   */
  avatars(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/avatars`
    );
  }

  /**
   * Adds a new avatar to the dashboard-auth-api.
   *
   * @param {any} formData - The form data containing the avatar information.
   * @return {Observable<any>} An observable containing the result of the avatar addition.
   */
  addAvatars(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/avatars`,
      formData
    );
  }

  /**
   * Removes an avatar from the system.
   *
   * @param {any} avatarId - The ID of the avatar to be removed.
   * @return {Observable<any>} An observable containing the result of the remove avatar operation.
   */
  removeAvatar(avatarId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/avatars/${avatarId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  avatarById(avatarId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/avatars/${avatarId}`
    );
  }

  updateAvatars(formData: any, avatarId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/avatars/${avatarId}?_method=PUT`,
      formData
    );
  }

  /**
   * Onboarding APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Retrieves onboarding data from the dashboard-auth-api.
   *
   * @return {Observable<any>} An observable containing the onboarding data.
   */
  onboarding(): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/onboarding`,
      {}
    );
  }

  onboardingById(onboardingId): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/onboarding/${onboardingId}`
    );
  }

  /**
   * Adds a new onboarding to the system.
   *
   * @param {any} formData - The data for the new onboarding.
   * @return {Observable<any>} An observable containing the result of the add onboarding operation.
   */
  addOnboardings(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/add-onboarding`,
      formData
    );
  }

  updateOnboardings(formData: any, boradingId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/onboarding/${boradingId}`,
      formData
    );
  }

  /**
   * Removes an onboarding from the system.
   *
   * @param {any} onboardingId - The ID of the onboarding to be removed.
   * @return {Observable<any>} An observable containing the result of the remove onboarding operation.
   */
  removeOnboarding(onboardingId: any): Observable<any> {
    return this.http
      .delete(
        `${this.domain}/api/dashboard-auth-api/onboarding/${onboardingId}`,
        { headers: this.createHeaders() }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * End of onboarding APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Countries APIS.
   * -----------------------------------------------------------------------------
   */
  /**
   * Retrieves a list of countries.
   *
   * @return {Observable<any>} An observable containing the list of countries
   */

  countries(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/countries`
    );
  }
  /**
   * Adds a new country to the system.
   *
   * @param {any} formData - The country data to be added.
   * @return {Observable<any>} The result of the country addition operation.
   */
  addCountry(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/countries`,
      formData
    );
  }
  postCountries(): Observable<any> {
    const authToken = localStorage.getItem("matlop_token");
    if (!authToken) {
      throw new Error("No authentication token found");
    }

    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    });

    return this.http.get(`${this.domain}/api/dashboard-auth-api/countries`, {
      headers,
    });
  }

  updateCountry(formData: any, countryId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/countries/${countryId}?_method=PUT`,
      formData
    );
  }

  /**
   * Removes a country from the system.
   *
   * @param {any} countryId - The ID of the country to be removed.
   * @return {Observable<any>} The result of the country removal operation.
   */
  removeCountry(countryId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/countries/${countryId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  /**
   * Edits a country in the system.
   *
   * @param {any} formData - The updated country data.
   * @param {any} countryId - The ID of the country to be edited.
   * @return {Observable<any>} The result of the country edit operation.
   */
  editCountry(formData: any, countryId: any): Observable<any> {
    return this.http
      .put(
        `${this.domain}/api/dashboard-auth-api/countries/${countryId}`,
        formData,
        { headers: this.createHeaders() }
      )
      .pipe(catchError(this.handleError.bind(this)));
  }
  /**
   * Retrieves a country by its ID.
   *
   * @param {any} countryId - The ID of the country to be retrieved.
   * @return {Observable<any>} An observable containing the country data.
   */
  countryById(countryId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/countries/${countryId}`
    );
  }

  /**
   * End of Countries APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Branches APIS.
   * -----------------------------------------------------------------------------
   */

  branches(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/branches`
    );
  }

  addBranches(formData: any): Observable<any> {
    console.log(formData);
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/branches`,
      formData
    );
  }

  updateBranches(formData: any, branchId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/branches/${branchId}?_method=PUT`,
      formData
    );
  }

  removeBranches(branchId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/branches/${branchId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  branchById(branchId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/branches/${branchId}`
    );
  }

  /**
   * End of Branches APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Students APIS.
   * -----------------------------------------------------------------------------
   */
  students(): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/students`,
      {}
    );
  }

  studentDevices(studentId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/student-devices`,
      { user_id: studentId }
    );
  }

  /**
   * End of Students APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Topics APIS.
   * -----------------------------------------------------------------------------
   */

  topics(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/topics`
    );
  }

  topicById(topicId: any): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/topics/${topicId}`
    );
  }

  addTopic(formData: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/topics`,
      formData
    );
  }

  updateTopic(formData: any, topicId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/topics/${topicId}?_method=PUT`,
      formData
    );
  }

  removeTopic(topicId: any): Observable<any> {
    return this.http
      .delete(`${this.domain}/api/dashboard-auth-api/topics/${topicId}`, {
        headers: this.createHeaders(),
      })
      .pipe(catchError(this.handleError.bind(this)));
  }

  levelSubjectTopics(levelId: any, subjectId: any): Observable<any> {
    return this.authenticatedPost(
      `${this.domain}/api/dashboard-auth-api/topics-by-level-subject`,
      { level_id: levelId, subject_id: subjectId }
    );
  }

  /**
   * End of topics APIS.
   * -----------------------------------------------------------------------------
   */

  /**
   * Devices APIS.
   * -----------------------------------------------------------------------------
   */

  devices(): Observable<any> {
    return this.authenticatedGet(
      `${this.domain}/api/dashboard-auth-api/devices`
    );
  }

  /**
   * User APIS.
   * -----------------------------------------------------------------------------
   */

  user(): Observable<any> {
    return this.authenticatedGet(`${this.domain}/api/auth-api/profile-details`);
  }
}
