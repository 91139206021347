import { CoreMenu } from "@core/types";

//?DO:htt:/localhos:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  {
    id: "dashboard",
    title: "Dashboard",
    translate: "MENU.DASHBOARD.COLLAPSIBLE",
    type: "collapsible",
    // role: ['Admin'], //? To hide collapsible based on user role
    icon: "home",
    children: [
      {
        id: "analytics",
        title: "Analytics",
        translate: "MENU.DASHBOARD.ANALYTICS",
        type: "item",
        icon: "circle",
        url: "dashboard/analytics",
      },
      {
        // If role is not assigned will be display to all
        id: "ecommerce",
        title: "eCommerce",
        translate: "MENU.DASHBOARD.ECOMMERCE",
        type: "item",
        icon: "circle",
        url: "dashboard/ecommerce",
      },
    ],
  },
  {
    id: "main",
    type: "section",
    title: "Main",
    translate: "MENU.MAIN.SECTION",
    icon: "home",
    children: [
      {
        id: "students",
        title: "Students",
        translate: "MENU.MAIN.STUDENTS",
        type: "item",
        icon: "users",
        url: "main/students",
      },
      {
        id: "devices",
        title: "Devices",
        translate: "MENU.MAIN.DEVICES",
        type: "item",
        icon: "users",
        url: "main/devices",
      },
    ],
  },
  {
    id: "settings",
    title: "Settings",
    translate: "MENU.SETTINGS.SECTION",
    type: "section",
    icon: "settings",
    children: [
      {
        id: "admins",
        title: "Admins",
        translate: "MENU.SETTINGS.ADMINS.COLLAPSIBLE",
        type: "collapsible",
        icon: "user-plus",
        children: [
          {
            id: "admins-list",
            title: "List",
            translate: "MENU.SETTINGS.ADMINS.LIST",
            type: "item",
            icon: "circle",
            url: "settings/admins",
          },
          {
            id: "admins-add",
            title: "Add",
            translate: "MENU.SETTINGS.ADMINS.ADD",
            type: "item",
            icon: "circle",
            url: "settings/add-admin",
          },
        ],
      },

      {
        id: "avatars",
        title: "Avatars",
        translate: "MENU.SETTINGS.AVATARS.COLLAPSIBLE",
        type: "collapsible",
        icon: "image",
        children: [
          {
            id: "avatars-list",
            title: "List",
            translate: "MENU.SETTINGS.AVATARS.LIST",
            type: "item",
            icon: "circle",
            url: "settings/avatars",
          },
          {
            id: "avatars-add",
            title: "Add",
            translate: "MENU.SETTINGS.AVATARS.ADD",
            type: "item",
            icon: "circle",
            url: "settings/add-avatar",
          },
        ],
      },
      {
        id: "countries",
        title: "Countries",
        translate: "MENU.SETTINGS.COUNTRIES.COLLAPSIBLE",
        type: "collapsible",
        icon: "flag",
        children: [
          {
            id: "countries-list",
            title: "List",
            translate: "MENU.SETTINGS.COUNTRIES.LIST",
            type: "item",
            icon: "circle",
            url: "settings/countries",
          },
          {
            id: "countries-add",
            title: "Add",
            translate: "MENU.SETTINGS.COUNTRIES.ADD",
            type: "item",
            icon: "circle",
            url: "settings/add-countries",
          },
        ],
      },
      {
        id: "onboardings",
        title: "Onboardings",
        translate: "MENU.SETTINGS.ONBOARDINGS.COLLAPSIBLE",
        type: "collapsible",
        icon: "sliders",
        children: [
          {
            id: "onboardings-list",
            title: "List",
            translate: "MENU.SETTINGS.ONBOARDINGS.LIST",
            type: "item",
            icon: "circle",
            url: "settings/onboardings",
          },
          {
            id: "onboardings-add",
            title: "Add",
            translate: "MENU.SETTINGS.ONBOARDINGS.ADD",
            type: "item",
            icon: "circle",
            url: "settings/add-onboarding",
          },
        ],
      },
      {
        id: "levels",
        title: "Levels",
        translate: "MENU.SETTINGS.LEVELS.COLLAPSIBLE",
        type: "collapsible",
        icon: "bar-chart-2",
        children: [
          {
            id: "levels-list",
            title: "List",
            translate: "MENU.SETTINGS.LEVELS.LIST",
            type: "item",
            icon: "circle",
            url: "settings/levels",
          },
          {
            id: "levels-add",
            title: "Add",
            translate: "MENU.SETTINGS.LEVELS.ADD",
            type: "item",
            icon: "circle",
            url: "settings/add-levels",
          },
        ],
      },
      {
        id: "school_types",
        title: "School Types",
        translate: "MENU.SETTINGS.SCHOOLTYPES.COLLAPSIBLE",
        type: "collapsible",
        icon: "book-open",
        children: [
          {
            id: "school_types-list",
            title: "List",
            translate: "MENU.SETTINGS.SCHOOLTYPES.LIST",
            type: "item",
            icon: "circle",
            url: "settings/schoolTypes",
          },
          {
            id: "school_types-add",
            title: "Add",
            translate: "MENU.SETTINGS.SCHOOLTYPES.ADD",
            type: "item",
            icon: "circle",
            url: "settings/add-school-types",
          },
        ],
      },
      {
        id: "subjects",
        title: "Subjects",
        translate: "MENU.SETTINGS.SUBJECTS.COLLAPSIBLE",
        type: "collapsible",
        icon: "book",
        children: [
          {
            id: "subjects-list",
            title: "List",
            translate: "MENU.SETTINGS.SUBJECTS.LIST",
            type: "item",
            icon: "circle",
            url: "settings/subjects",
          },
          {
            id: "subjects-add",
            title: "Add",
            translate: "MENU.SETTINGS.SUBJECTS.ADD",
            type: "item",
            icon: "circle",
            url: "settings/add-subject",
          },
        ],
      },
      {
        id: "quizzes",
        title: "Quizzes",
        translate: "MENU.SETTINGS.QUIZZES.COLLAPSIBLE",
        type: "collapsible",
        icon: "file-text",
        children: [
          {
            id: "quizzes-list",
            title: "List",
            translate: "MENU.SETTINGS.QUIZZES.LIST",
            type: "item",
            icon: "circle",
            url: "settings/quizzes",
          },
          {
            id: "quizzes-add",
            title: "Add",
            translate: "MENU.SETTINGS.QUIZZES.ADD",
            type: "item",
            icon: "circle",
            url: "settings/add-quiz",
          },
        ],
      },
      {
        id: "questions",
        title: "Questions",
        translate: "MENU.SETTINGS.QUESTIONS.COLLAPSIBLE",
        type: "collapsible",
        icon: "help-circle",
        children: [
          {
            id: "questions-list",
            title: "List",
            translate: "MENU.SETTINGS.QUESTIONS.LIST",
            type: "item",
            icon: "circle",
            url: "settings/questions",
          },
          {
            id: "questions-add",
            title: "Add",
            translate: "MENU.SETTINGS.QUESTIONS.ADD",
            type: "item",
            icon: "circle",
            url: "settings/add-question",
          },
        ],
      },
      {
        id: "topics",
        title: "Topics",
        translate: "MENU.SETTINGS.TOPICS.COLLAPSIBLE",
        type: "collapsible",
        icon: "list",
        children: [
          {
            id: "topics-list",
            title: "List",
            translate: "MENU.SETTINGS.TOPICS.LIST",
            type: "item",
            icon: "circle",
            url: "settings/topics",
          },
          {
            id: "topics-add",
            title: "Add",
            translate: "MENU.SETTINGS.TOPICS.ADD",
            type: "item",
            icon: "circle",
            url: "settings/add-topic",
          },
        ],
      },
      {
        id: "videos",
        title: "Videos",
        translate: "MENU.SETTINGS.VIDEOS.COLLAPSIBLE",
        type: "collapsible",
        icon: "video",
        children: [
          {
            id: "videos-list",
            title: "List",
            translate: "MENU.SETTINGS.VIDEOS.LIST",
            type: "item",
            icon: "circle",
            url: "settings/videos",
          },
          {
            id: "videos-add",
            title: "Add",
            translate: "MENU.SETTINGS.VIDEOS.ADD",
            type: "item",
            icon: "circle",
            url: "settings/add-video",
          },
        ],
      },
      {
        id: "categories",
        title: "Categories",
        translate: "MENU.SETTINGS.CATEGORIES.COLLAPSIBLE",
        type: "collapsible",
        icon: "grid",
        children: [
          {
            id: "categories-list",
            title: "List",
            translate: "MENU.SETTINGS.CATEGORIES.LIST",
            type: "item",
            icon: "circle",
            url: "settings/categories",
          },
          {
            id: "categories-add",
            title: "Add",
            translate: "MENU.SETTINGS.CATEGORIES.ADD",
            type: "item",
            icon: "circle",
            url: "settings/add-category",
          },
          {
            id: "packages",
            title: "Packages",
            translate: "MENU.SETTINGS.PACKAGES.COLLAPSIBLE",
            type: "collapsible",
            icon: "gift",
            children: [
              {
                id: "packages-list",
                title: "List",
                translate: "MENU.SETTINGS.PACKAGES.LIST",
                type: "item",
                icon: "circle",
                url: "settings/packages",
              },
              {
                id: "packages-add",
                title: "Add",
                translate: "MENU.SETTINGS.PACKAGES.ADD",
                type: "item",
                icon: "circle",
                url: "settings/add-package",
              },
            ],
          },
        ],
      },
      {
        id: "bundles",
        title: "Bundles",
        translate: "MENU.SETTINGS.BUNDLES.COLLAPSIBLE",
        type: "collapsible",
        icon: "package",
        children: [
          {
            id: "bundles-list",
            title: "List",
            translate: "MENU.SETTINGS.BUNDLES.LIST",
            type: "item",
            icon: "circle",
            url: "settings/bundles",
          },
          {
            id: "bundles-add",
            title: "Add",
            translate: "MENU.SETTINGS.BUNDLES.ADD",
            type: "item",
            icon: "circle",
            url: "settings/add-bundle",
          },
        ],
      },
      {
        id: "branches",
        title: "Branches",
        translate: "MENU.SETTINGS.BRANCHES.COLLAPSIBLE",
        type: "collapsible",
        icon: "shuffle",
        children: [
          {
            id: "branches-list",
            title: "List",
            translate: "MENU.SETTINGS.BRANCHES.LIST",
            type: "item",
            icon: "circle",
            url: "settings/branches",
          },
          {
            id: "branches-add",
            title: "Add",
            translate: "MENU.SETTINGS.BRANCHES.ADD",
            type: "item",
            icon: "circle",
            url: "settings/add-branches",
          },
        ],
      },
      {
        id: "terms_and_conditions",
        title: "Terms & Conditions",
        translate: "MENU.SETTINGS.TERMS_AND_CONDITIONS.COLLAPSIBLE",
        type: "collapsible",
        icon: "file-text",
        children: [],
      },
      {
        id: "faq",
        title: "FAQs",
        translate: "MENU.SETTINGS.FAQS.COLLAPSIBLE",
        type: "collapsible",
        icon: "file-text",
        children: [],
      },
    ],
  },
];
