import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "environments/environment";
import { User, Role } from "app/auth/models";
import { ToastrService } from "ngx-toastr";
import { HttpHeaders } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;
  public currentToken: Observable<string>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  public tokenSubject: BehaviorSubject<string>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();

    this.tokenSubject = new BehaviorSubject<string>(
      JSON.parse(localStorage.getItem("currentUserToken"))
    );
    this.currentToken = this.tokenSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  // getter: currentUserValue
  public get currentUserToken(): string {
    return this.tokenSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Admin
    );
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Client
    );
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .post<any>(
        `${environment.developmentDomain}/api/dashboard-api/login`,
        {
          email,
          password,
        },
        { headers }
      )
      .pipe(
        map((data) => {
          console.log(data);
          // login successful if there's a jwt token in the response
          if (data.data.access_token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem("currentUser", JSON.stringify(data.data.user));
            localStorage.setItem(
              "currentUserToken",
              JSON.stringify(data.data.access_token)
            );

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                "You have successfully ",
                "👋 Welcome, " +
                  data.data.user.first_name +
                  data.data.user.last_name +
                  "!",
                { toastClass: "toast ngx-toastr", closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(data.data.user);
            this.tokenSubject.next(data.data.access_token);
          }

          return data.data.user;
        })
      );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentUserToken");
    // notify
    this.currentUserSubject.next(null);
    this.tokenSubject.next(null);
  }
}
